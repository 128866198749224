<template>
    <div>
        <div class="page-header back-image6"></div>
        <div class="col-md-1">
            <div class="wrapper-content bg-white pinside40">
                <div class="cont-table">
                    <a-col class="gralTitulos" style="border-bottom: 3px solid rgb(188, 188, 188);" width="90%">
                        <h3 class="title-section">Detalle del Proceso Número:<span class="number_process"> {{
                            dataTender.number_proccess
                        }}</span> </h3>
                    </a-col>
                    <a-row :gutter="24" type="flex">
                        <a-col :span="24" class="mb-24">
                            <div class="div-table-container">
                                <div class="card-header-table">
                                    <div class="bg-gradient-primary">
                                        <h5 class="title-table">Listado</h5>
                                    </div>
                                </div>

                                <table class="table">
                                    <thead class="tr-thead">
                                        <tr class="tr-columns-data">
                                            <th class="th-colum-item">Nombre</th>
                                            <th class="th-colum-item">Descripción</th>
                                            <th class="th-colum-item">Tipo documento</th>
                                            <th class="th-colum-item">Fecha</th>
                                        </tr>

                                    </thead>
                                    <tbody class="tbody-container">
                                        <tr class="tr-body" v-for="(item, index) in listAnexos" :key="index">
                                            <div class="tr-columns-data-body">
                                                <td class="th-colum-item">
                                                    <a target="_blank" rel="nofollow" :href="item.urlPdf">{{ item.name
                                                    }}</a>
                                                </td>
                                                <td class="th-colum-item"> {{ item.description }} </td>
                                                <td class="th-colum-item">
                                                    <img src="../../../assets/img/icons-pdf.png" alt="logo_pdf">
                                                </td>
                                                <td class="th-colum-item"> {{ item.datePublication }} </td>
                                            </div>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DefaultHeader2 from '../../../components/Headers/DefaultHeader2.vue'
import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';
import { db, storage } from '../../../../firebaseConfig';

export default {
    components: {
        DefaultHeader2,
        LoaderDefault
    },
    data() {
        return {
            listAnexos: [],
            _idParam: "",
            idNameAnexo: '',
            number_licitacion: "",
            dataTender: {},
            showFormEditAnexo: false,
            isLoading: false,
            dataItemAnexo: {
                name: "",
                description: "",
                datePublication: "",
                urlPdf: ""
            },
        }
    },
    mounted() {
        this._idParam = this.$route.params._id
        this.getInfoTenderAnexos(this._idParam)
    },
    methods: {
        async getInfoTenderAnexos(_idDoc) {
            this.isLoading = true
            await db.collection('tenders')
                .doc(_idDoc)
                .get().then((querySnapshot) => {
                    this.dataTender = querySnapshot.data()
                    this.listAnexos = querySnapshot.data().anexos;
                    this.isLoading = false
                })
        },
    },
}
</script>
<style lang="css">
.back-image6 {
    background: url('../../../assets/img/fotoDetailLicitacion.png') no-repeat center;
    background-size: cover;
}

.cont-table {
    max-width: 1100px;
    margin: 0 auto;
}
</style>